export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.gonation': { display: 'none' },
    '.container': {
      padding: ['1rem', '1.5rem'],
      position: 'absolute',
      backgroundColor: 'transparent',
      top: '0rem',
      '.smallNavMenu': {
        // margin: '0rem auto 0rem 2rem',
        '.navItem': {
          textShadow: '1px 1px 10px black',
          color: 'white',
          margin: '0rem 1rem',
          a: {
            fontSize: '1.25rem',
            ':hover': {
              textDecoration: 'underline',
              color: 'white'
            }
          }
        },
        '.react-reveal': {
          opacity: '1'
        }
      }
    },
    '.containerScrolled': {
      border: 'none',
      padding: '0.5rem 1rem',
      backgroundColor: 'background',
      // width: 'fit-content',
      position: 'fixed',
      right: '0rem',
      top: '0rem',
      left: 'unset',
      // borderBottom: '1px solid',
      // borderColor: 'primary',

      '.smallNavMenu': {
        // margin: '0rem auto 0rem 2rem',
        '.navItem': {
          color: 'text',
          a: {
            fontSize: '1rem',
            ':hover': {
              textDecoration: 'underline',
              color: 'lightgrey'
            }
          }
        },
        '.react-reveal': {
          opacity: '1'
        }
      }
    },

    // top level nav styles

    '.logoLocationContainer': {
      // display: 'none',
      padding: '0rem',
      position: 'static',
      margin: '0rem auto 0rem 0rem'
    },
    '.logo': {
      // display: 'none',
      a: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      img: {
        maxHeight: ['110px', '120px', '130px', '145px', '160px'],
        // filter: 'brightness(0) invert(1)',
        // maxWidth: ['', '', 'unset', 'unset'],
        filter: 'unset'
      }
    },
    '.logoScrolled': {
      // display: 'none',
      img: {
        maxHeight: ['60px', '125px'],
        filter: 'unset'
      }
    },

    // hamburger
    '.hamburger': {
      borderRadius: '100px',
      padding: '0.75rem',
      borderColor: 'primary',
      backgroundColor: 'rgba(255,255,255, 0.6)',
      display: ['', '', ''],
      order: '5',
      margin: '0rem',
      '> div': {
        backgroundColor: 'primary'
      }
    },

    '.hamburgerOpen': {
      borderColor: 'white',
      borderRadius: '100px',
      padding: '0.75rem',
      borderColor: 'primary',
      backgroundColor: 'rgba(255,255,255, 0.6)',
      display: ['', '', ''],
      order: '5',
      margin: '0rem',
      '> div': {
        backgroundColor: 'light'
      }
    },

    '.socialIconsContainerDesktop': {
      margin: ['0rem 1rem 0rem auto', '', '', '0rem'],
      display: 'flex',
      order: '4',
      svg: {
        border: 'solid 1px',
        borderColor: 'secondary',
        height: '40px',
        width: '40px',
        padding: '0.25rem',
        borderRadius: '100px',
        backgroundColor: 'rgba(255, 255, 255, 0.35)',
        path: {
          fill: 'secondary'
        }
      }
    },

    '.smallNavMenu': {
      order: '3',
      '.navItemDropdownMenu': {
        backgroundColor: 'black'
      }
    },

    // nav menu styles

    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'primary',

      '.navMenuLogo': {
        display: 'none'
      },

      '.navItem': {
        a: {
          color: 'light',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },

      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },

      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        color: 'white'
      },

      // widgets

      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },

      '.socialIconsContainer': {
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          svg: {
            path: {
              fill: 'white'
            }
          }
        }
      },

      '.phoneContainer': {
        display: 'flex',
        border: 'none',
        margin: '0rem 1rem',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        a: {
          backgroundColor: 'primary',
          padding: '0.5rem',
          color: 'light',
          textAlign: 'center',
          margin: '0.5rem 0rem'
        }
      }
    },

    '.navBlock': {
      right: 'unset',
      top: '200vh'
    }
  },

  footer: {
    backgroundColor: 'primary',
    borderTop: 'solid 5px',
    borderColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['0.5rem 0.5rem 6rem', '', '0.5rem 3rem'],
    '.image': {
      // filter: 'brightness(0) invert(1)',
      display: 'none'
    },
    '.multiButtonContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        width: 'fit-content',
        fontFamily: 'heading'
      }
    },
    '.contactDetails-container': {
      display: 'none'
    },
    '.copyright': {
      width: '50%',
      color: 'white',
      textAlign: 'center'
    },
    '.socialIconsContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '1rem',
      a: {
        svg: {
          width: '40px',
          height: '40px',
          padding: '0.5rem',
          border: 'solid 1px white',
          borderRadius: '100px',
          ':hover': {
            backgroundColor: 'grey'
          }
        },
        margin: '0.5rem'
      }
    },
    '.gonationLogo': {
      width: '50%'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem'],
    fontWeight: 'normal',
    letterSpacing: '4px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    textTransform: 'uppercase',
    fontFamily: 'heading',
    color: 'inherit',
    color: 'black'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
    marginBottom: '1.5rem',
    border: 'none',
    color: 'inherit',
    fontFamily: 'subheading',
    fontWeight: '200'
  },
  text: {
    p: {
      color: 'inherit',
      lineHeight: '1.75',
      fontSize: 'inherit',
      textAlign: 'center'
    }
  },

  sideBySide1: {
    backgroundColor: 'background',
    padding: ['1rem', '', '1rem 1rem 2rem'],
    margin: ['', '', '0rem 0rem 7rem'],
    '.lazyload-wrapper': {
      width: ['100%', '', '70%', '65%'],
      minHeight: ['', '', '50vh'],
      maxHeight: ['', '', '80vh'],
      justifyContent: 'flex-start',
      img: {
        border: 'solid 10px white',
        borderRadius: '20px'
      }
    },

    '.content': {
      width: ['100%', '', '30%', '35%'],
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      padding: ['2rem 1rem', '2rem', '3rem', '3rem', '4rem'],
      backgroundColor: 'primary',
      height: 'fit-content',
      color: 'white',
      position: 'relative',
      bottom: ['', '', '-3rem'],
      borderRadius: '20px',
      alignSelf: 'flex-end',
      left: ['', '', '2rem'],
      backgroundImage: 'url(https://www.transparenttextures.com/patterns/diagonales-decalees.png)'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'white',
      borderTop: 'dotted 2px white',
      borderBottom: 'dotted 2px white'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'secondary'
    },
    '.text': {
      variant: 'customVariants.text',
      p: {
        textAlign: 'left',
        color: 'white'
      }
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      marginBottom: '0rem'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    'div.content': {
      left: ['', '', '-2rem'],
      backgroundColor: '#8d1666',
      backgroundImage: 'url(https://www.transparenttextures.com/patterns/asfalt-light.png)'
    }
  },

  centerBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '90vh',
    color: 'white',
    textAlign: 'center',
    padding: ['6rem 1rem', '8rem 1rem'],
    '.section': {
      maxWidth: 'unset',
      width: '95%'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'white',
      fontSize: ['3rem', '4rem', '5rem', '6rem', '7rem'],
      textShadow: '6px 6px 0px #327d8a'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'secondary',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem']
    },

    '.text': {
      variant: 'customVariants.text',
      color: 'white'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  centerBlock2: {
    variant: 'customVariants.centerBlock',
    'h2.title': {
      textShadow: '6px 6px 0px #f01295'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.hero_content_container': {
      left: ['2rem', '3rem', '4rem', '5rem']
    },

    '.title': {
      variant: 'customVariants.title',
      color: 'white',
      fontSize: ['5rem', '7rem', '9rem', '12.5rem', '13.5rem'],
      textShadow: '1px 1px 20px black'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'secondary',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem'],
      textShadow: '1px 1px 20px black'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },

    '.slick-arrow': {
      height: '60px',
      width: '60px',
      color: '#3e86bf !important',
      filter: 'drop-shadow(0px 0px 6px rgba(0,0,0,0.8))'
    },

    '.slick-prev': {
      left: '1rem'
    },

    '.slick-next': {
      right: '1rem'
    },

    '.slick-dots': {
      li: {
        button: {
          '::before': {
            color: '#327d8adb',
            fontSize: '1.5rem'
          }
        }
      }
    },
    '.slick-dots li.slick-active button:before': {
      color: 'primary'
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },

  // homepageContact: {
  //   variant: 'customVariants.sideBySide1',
  // },

  homepageText: {
    variant: 'customVariants.centerBlock'
  },

  homepageText2: {
    variant: 'customVariants.centerBlock2'
  },

  homepageTestimonials: {
    maxWidth: '700px',
    margin: ' 0rem auto 4rem',
    backgroundColor: 'secondary',
    backgroundColor: 'tertiary',
    border: 'dashed 7px',
    borderColor: 'secondary',
    borderRadius: '20px',
    width: '90%',

    backgroundImage: 'url(https://www.transparenttextures.com/patterns/light-paper-fibers.png)',

    '.menuSectionTitle': {
      fontSize: ['2rem', '2.5rem', '3.5rem', '4rem', '4.5rem'],
      borderTop: 'dotted 4px',
      borderBottom: 'dotted 4px',
      borderColor: 'primary',
      color: 'primary'
    },

    '.menuItemName': {
      color: 'secondary'
    },

    '.menuItemNameContainer': {
      textAlign: 'center'
    },
    '.menuItemDescription': {
      textAlign: 'center',
      width: '100%',
      padding: '1rem',
      lineHeight: '1.6'
    },

    '.slick-slider': {
      '.slick-arrow': {
        height: '40px',
        width: '40px',
        color: '#3e86bf !important',
        right: 'unset',
        left: 'unset',
        top: 'unset',
        bottom: '-3rem',
        background: 'rgba(255,255,255,0.5)'
      },

      '.slick-prev': {
        left: '1rem'
      },

      '.slick-next': {
        left: '4.5rem'
      },

      '.slick-dots': {
        li: {
          button: {
            '::before': {
              color: '#327d8adb',
              fontSize: '1.5rem'
            }
          }
        }
      },
      '.slick-dots li.slick-active button:before': {
        color: 'primary'
      }
    }
  },

  homepageShout: {
    backgroundColor: 'background',
    backgroundSize: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left center',
    marginBottom: '3rem',
    padding: ['1rem', '', '2rem 1rem', '3rem 2rem 10rem'],
    flexWrap: ['wrap', 'wrap', 'wrap', 'wrap'],
    margin: '0rem 0rem',
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '3px solid',
      borderColor: 'secondary',
      fontFamily: 'heading',
      width: ['', '', '80%'],
      color: 'black',
      fontSize: ['3rem', '3.5rem', '4rem', '4.5rem', '5rem'],
      backgroundColor: 'transparent'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['2rem', '2rem', '2.5rem', '3rem', '4rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'primary'],
      fontWeight: 'normal',
      color: 'white',
      padding: ['', '', '0rem 2rem'],
      backgroundColor: ['#ec008bc2'],
      textTransform: 'uppercase',
      padding: ['2rem', '2rem'],
      marginTop: '3rem',
      marginRight: ['', '', '3rem'],
      width: ['', '', '55%'],
      whiteSpace: 'break-spaces'
    },
    '.date': {
      backgroundColor: 'primary',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem',
      maxWidth: 'unset',
      color: 'white',
      flexGrow: '1',
      fontWeight: 'bold',
      justifyContent: 'center',
      position: 'relative',
      left: ['', '', '', '-3rem'],
      bottom: ['', '', '', '-2rem'],
      display: 'none'
    },
    '.imageContainer': {
      position: 'relative',
      top: ['', '', '', '-6rem'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer',
      flexGrow: '1',
      width: ['', '', '40%'],
      '.imageFill': {
        paddingBottom: ['130%']
      },
      img: {
        objectPosition: 'top'
      }
    },
    '.shoutCTABtns ': {
      border: ['', '', '', 'solid 8px'],
      borderColor: ['', '', '', 'background'],
      flexDirection: ['row', 'row', 'row', 'column'],
      width: ['100%', '', '', 'auto'],
      a: {
        width: ['50%', '', '', '100%'],
        writingMode: ['unset', 'unset', 'unset', 'tb'],
        padding: '1rem',
        backgroundColor: 'transparent',
        color: 'primary',
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        ':hover': {
          backgroundColor: 'primary',
          color: 'white'
        }
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  bio: {
    variant: 'customVariants.sideBySide1',
    marginTop: '0rem',
    backgroundColor: '#e9e9e2',
    padding: '5rem 2rem 0rem',
    'div.content': {
      width: ['', '', '60%']
    },

    'div.lazyload-wrapper': {
      overflow: 'hidden',
      width: ['', '', '40%'],
      img: {
        objectFit: 'contain',
        objectPosition: 'top'
      }
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  // menu: {},
  menu: {
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.75rem', '2.5rem', '2.5rem', '2.5rem'],
      backgroundColor: 'primary',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '1rem',
      color: 'white',
      margin: '1rem 0rem 2rem'
    },

    '.menuSectionDescription': {
      fontWeight: '200',
      textAlign: 'center',
      maxWidth: ['100%', '75%'],
      margin: '0rem auto 2rem'
    },

    '.menuItemsContainer': {
      margin: ['0rem 1rem', '0rem 2rem']
    },

    '.menuItemContainerImgActive': {
      height: '100%',
      width: ['100%', '', 'calc(50% - (2rem * 2))'],

      '.menuItemInnerContainer': {
        flexDirection: ['column', '', 'row'],
        justifyContent: 'center',
        alignItems: 'center',
        '.menuItemContentContainer': {
          width: ['100%', '', '', '70%'],
          color: 'white',
          '.menuItemName': {
            variant: 'customVariants.title',
            fontSize: ['1.25rem', '1.5rem', '1.5rem', '1.5rem', '1.5rem'],
            // width: ['60%', '75%'],
            textShadow: 'none',
            marginBottom: '0rem',
            color: 'secondary'
          },
          '.itemVariantsContainer': {
            display: 'flex',
            flexWrap: 'wrap'
          },
          '.variantsContainer': {
            display: 'flex',
            flexWrap: 'wrap',
            flexGrow: '1',
            margin: '0.5rem 1rem 0.5rem 0rem'
            // width: ['33.3%'],
          },
          '.variantContainer': {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            fontFamily: 'body2',
            backgroundColor: '#efefefba',
            padding: '0.75rem',
            color: 'secondary',
            borderRadius: '10px'
          },
          '.menuItemPriceLabel': {
            fontWeight: 'bold',
            fontSize: '0.8rem',
            color: 'secondary',
            marginBottom: '0.25rem'
          },
          '.variantContainerFloatTopRight': {
            fontSize: ['1.25rem', '1.5rem', '2rem'],
            color: 'secondary'
          },
          '.menuItemLabelOptions': {
            fontSize: '0.7rem',
            maxWidth: '350px'
          },
          '.menuItemPriceVariants': {
            fontSize: '0.7rem',
            maxWidth: '350px',
            color: 'secondary'
          },
          '.menuItemDescription': {
            width: '100%',
            // backgroundColor: 'white',
            opacity: '1',
            fontSize: '1rem',
            color: 'text',
            fontFamily: 'body',
            lineHeight: '1.5'
          },
          '.menuItemPrice': {
            color: 'secondary'
          }
        },

        '.menuItemImageContainer': {
          width: ['100%', '', '', '30%'],

          '.menuItemImg': {
            // objectPosition: 'top',
          }
        }
      }
    },
    '.menuItemPageContent': {
      '.itemVariantsContainer': {
        display: 'flex',
        flexWrap: 'wrap'
      },
      '.variantsContainer': {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: '1',
        margin: '0.5rem 1rem 0.5rem 0rem'
        // width: ['33.3%'],
      },
      '.variantContainer': {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'body2',
        backgroundColor: '#efefefba',
        padding: '0.75rem',
        borderRadius: '10px'
      },
      '.menuItemPriceLabel': {
        fontWeight: 'bold',
        fontSize: '0.8rem',
        marginBottom: '0.25rem'
      },
      '.variantContainerFloatTopRight': {
        fontSize: ['1.25rem', '1.5rem', '2rem']
      },
      '.menuItemLabelOptions': {
        fontSize: '0.7rem',
        maxWidth: '350px'
      },
      '.menuItemPriceVariants': {
        fontSize: '0.7rem',
        maxWidth: '350px'
      },
      '.menuItemDescription': {
        width: '100%',
        backgroundColor: 'white',
        opacity: '1',
        lineHeight: '1.7',
        padding: '1rem 0.25rem'
      }
    },
    '.menuItemButton': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.albumName': {
      display: 'none'
    },
    '.albumPhotos': {
      gridGap: '0rem'
    },
    '.albumImage': {
      padding: '0rem'
    },
    '.albumsCTAActive': {
      textDecoration: 'none',
      opacity: '1'
    },
    '.albumsCTA': {
      opacity: '0.8'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',

    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      maxWidth: '1000px',
      color: 'secondary'
    },
    '.text': {
      color: 'white'
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '1.75rem', '1.75rem', '1.75rem', '1.75rem'],
      alignItems: 'center',
      color: 'black'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.textContent': {
      backgroundColor: 'lightgrey'
    },
    '.imageContainer': {
      backgroundColor: 'white',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  }
}
