export default {
  text: '#111',
  primary: '#246939',
  secondary: '#AC275F',
  tertiary: '#29733C',
  quaternary: '#00655E',
  background: '#f7f7f7',
  backgroundSecondary: '#f7f7f7',
  light: '#FFF',
  dark: '#000'
}
